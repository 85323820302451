.subTitle{

  right: 4rem;
  bottom: 3rem;
  position: absolute;
  text-align: right;
  font-family: Inter-Medium;
  line-height: 2rem;
  font-size: 1.2rem;
  color: #1976d2;
}
@media screen and (max-height: 800px){
  .subTitle{
    color: #1976d2;
    right: 4rem;
    bottom: 1rem;
    position: absolute;
    font-size: 1.2rem;
    text-align: right;
    font-family: Inter-Medium;
    line-height: 2rem;
  }
}
@media screen and (max-width: 1280px) {
  .loginCardWrapper {
    position: absolute !important;
    width: 300px;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 22;
    height: auto !important;
  }
}
@media screen and (max-height: 890px) {
  .loginCardWrapper {
    height: auto !important;
  }
}
