.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(80rem, 90%);
  background: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);
  height: min(600px , 90%);
  }
  .wrapperContent{
      display: inline-block;
      width: 100% ;
      height: 100% ; 
      
  }
  /* .heading{
    display: inline-block;
    width: calc(100% - 2rem);
    height: calc(8% - 2rem);
    padding: 1rem;
    font-family: Inter-Regular;
    font-style: normal;
    line-height: 2.4rem;
    font-size: 1.3rem;
    font-weight: 800;
  } */
  .wrapperTable{
      display: inline-block;
      width: 100%;
      height: 85%;
      max-height: 85%;
  }
  .footer{
    display: inline-block;
    width: calc(100% - 2rem);
    height: calc(12% - 2rem);
    text-align: right;
    padding: 1rem;
  }
  .ButtonWrapper{
    display: inline-block;
    width: 20%;
    
  }
