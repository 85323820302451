.ModalWrapper{
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(80rem , 90%);
  background: #ffffff;

}
.wrapperContent {
	/* width: 90%; */
	max-height: 46rem;
	display: flex;
	flex-direction: column;
}
.fieldBlock{
  margin-bottom: 2rem;
}
.mapInputWrapper{
	width: calc(50% - 1rem);
}
.mapInputWrapper > .fieldBlock{

}
.mapBox{
  width: calc(50% - 1rem);
  /* margin-right: 2rem; */
}