.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(1000px , 90%);
  background: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);

  display: flex;
  flex-direction: column;
  height: 90%;
}
/* * header */

.modalWrapper > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3.5rem 2rem 2rem;
  border-bottom: 1px solid #eaecf0;
}
.modalWrapper > .header h1 {
  margin: 0 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #344054;
}
.modalWrapper > .header img:first-child {
  width: 1.3rem;
}
.modalWrapper > .header img:last-child {
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  width: 1.2rem;
}

.mainContainer {
  background: #f9fafb;
  /* padding: 2rem; */
  border-bottom: 1px solid #eaecf0;
  flex: 1;
}


.mainTabPanelWrapper {
  background: #ffffff;
  /* border: 1px solid #eaecf0; */
  box-sizing: border-box;
  /* box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06); */
  /* border-radius: 8px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mainTabPanelHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 2rem; */
  padding: 2rem 2rem 1rem 2rem;
}

/* .mainTabPanelHeader > div:first-child {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #667085;
  margin-bottom: 1rem;
}
.mainTabPanelHeader > div:first-child > p {
  margin: 0;
}
.mainTabPanelHeader > div:first-child > p:first-child {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #101828;
} */
.mainTabPanelHeader > div:last-child > div {
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  flex: 1;
}
.mainTabPanelHeader > div:last-child {
  display: flex;
  flex-wrap: wrap;
  /* column-gap: 1.5rem; */
}

.searchAndFilterWrapper {
  /* border: solid 1px #eaecf0; */
  /* padding: 1.2rem 1.6rem 0.5rem 1.6rem; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.searchAndFilterWrapper > div:first-child {
  flex-grow: 5;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.searchAndFilterWrapper > div:last-child {
  display: flex;
  margin-bottom: 0.5rem;
  flex-grow: 5;
  align-items: center;
  /* column-gap: 12px; */
}
.searchAndFilterWrapper > div:last-child > div:first-child {
  /*? for search */
  /* min-width: calc(100% - 10rem); */
  width: calc(100% - 11rem);
  margin-right: 1rem;
}
/* ? table */
.examCenterWrapper {
  /* height: calc(100vh - 44rem); */
  width: 100%;
  flex: 1;
}

.inputCapacity { 
  width: 100%;
  padding: 1rem;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.inputCapacity:focus-visible {
  outline: none;
  border: 1px solid #D6BBFB;
/* Shadow/xs focused 4px primary-100 */

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
}







/* Footer */

.footer {
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
}
.footer > div {
  margin: 0 0.5rem;
}

/* @media screen and (max-width: 450px) {
  .modalWrapper {
    width: 100%;
    height: 100%;
  }
} */