.examFormContainer {
  padding: 3.2rem;
  background-color: #f9fafb;
}
.header {
  padding-bottom: 3.2rem;
  border-bottom: 1px solid #d0d5dd;
}
.arrowBtnWrapper {
  margin-bottom: 2.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.arrowBtnName {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #6941c6;
  margin-left: 1.2rem;
  font-family: "Inter-Medium";
}
.title {
  font-weight: 500;
  font-size: 3rem;
  line-height: 3.8rem;
  color: #101828;
}
.formWrapper {
  margin: 3.2rem 11.4rem;
  background-color: #fff;
  overflow-y: auto;
  height: calc(100vh - 31rem);
  padding: 3.2rem 0;
}
.progressBar {
  padding-bottom: 3.2rem;
}
.form {
  width: 70%;
  margin: 0 auto;
}
.formTitle {
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
  color: #101828;
  margin-bottom: 3.2rem;
  display: flex;
  align-items: center;
}
label {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #344054;
}
a {
  text-decoration: none;
}
.cardWrapper {
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 0px 1px 2px rgb(16 24 40 / 6%);
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem;
}
.inputDiv {
  margin-bottom: 2.6rem;
}
.flexDiv {
  display: flex;
  margin-bottom: 2.6rem;
  justify-content: space-between;
}
.halfDiv {
  width: 48.1%;
}
.ckeditor {
  font-size: 1.4rem;
  margin-top: 0.6rem;
}
.imageUploadWrapper {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 1rem 1.4rem;
  text-align: center;
  cursor: pointer;
  margin-top: 0.6rem;
}
.redImageUploadWrapper {
  background: #ffffff;
  border: 1px solid red;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 1rem 1.4rem;
  text-align: center;
  cursor: pointer;
  margin-top: 0.6rem;
}
.uploadImageText {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #667085;
}
.uploadImageSpan {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #6941c6;
}
.formItems {
  margin-bottom: 2.4rem;
}
.draggableIcon {
  margin-right: 2.3rem;
  margin-top: 0.6rem;
}
.draggableDropdown {
  display: inline-block;
  width: 47%;
}
.menuOptionsIcon {
  cursor: pointer;
  margin-top: 0.6rem;
  margin-left: 4.9rem;
}
.footer {
  /* position: fixed; */
  bottom: 0;
  padding: 1rem 11.4rem;
  border-top: 1px solid #d0d5dd;
  width: -webkit-fill-available;
  background: #ffffff;
  box-shadow: 0px -1px 3px rgb(16 24 40 / 5%), 0px -1px 2px rgb(16 24 40 / 2%);
  display: flex;
  justify-content: flex-end;
}
.footerFirst {
  /* position: fixed; */
  bottom: 0;
  padding: 1rem 11.4rem;
  border-top: 1px solid #d0d5dd;
  width: -webkit-fill-available;
  background: #ffffff;
  box-shadow: 0px -1px 3px rgb(16 24 40 / 5%), 0px -1px 2px rgb(16 24 40 / 2%);
  display: flex;
  justify-content: space-between;
}
.backBtnWrapper {
  width: 70%;
  display: inline-block;
}
.backBtn {
  width: 11%;
}
.btnWrapper {
}
.nextBtn {
  width: 23%;
  display: inline-block;
}
.addBtn {
  background: #f9f5ff;
  border: 1px solid #7f56d9;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: #7f56d9;
  padding: 1rem 3.9rem;
  cursor: pointer;
}
.deleteIcon {
  font-size: 2.5rem !important;
  cursor: pointer;
  margin-left: 1rem;
  color: rgb(127, 86, 217);
}
/* Add Card Section */
.formCard {
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 0.5rem 1rem 1rem 1rem;
  margin: 1rem;
}
.formCard > .cardWrapperDrag {
  text-align: center;
}
.formCard > .cardWrapperDrag > img {
  transform: rotate(90deg);
  width: 2rem;
}

.formCard > .inputsWrapper {
  display: flex;
  flex-direction: row;
}
.formCard > .inputsWrapper > .cardInput {
  margin: 0.5rem;
  flex: 50%;
}

.formCard > .optionsWrapper > .optionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.formCard > .optionsWrapper > .optionWrapper > div {
  margin: 0.5rem;
}
.formCard > .optionsWrapper > .optionWrapper > div:last-of-type {
  flex: 1;
}

.formCard > .cardFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.formCard > .cardFooter > div {
  margin: 0.5rem;
}
.formCard > .cardFooter > .requiredWrapper {
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.roundBtn {
  background: rgb(127, 86, 217);
  border-radius: 50%;
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin-left: 1rem;
}
.whiteRoundBtn {
  background: white;
  border-radius: 50%;
  padding: 0.5rem 0.5rem 0 0.5rem;
  margin-left: 1rem;
}

.column {
  display: inline-block;
  text-align: center;
}
.column img {
  display: block;
  width: 30rem;
  height: 30rem;
}
.column {
  position: relative;
}
.column .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  opacity: 0;
  transition: all 0.8s ease;
}
.column .text h1 {
  margin: 0;
  color: white;
}
.column:hover .text {
  opacity: 1;
}
.column:hover img {
  -webkit-filter: sepia(90%);
}

@media screen and (max-width: 950px) {
  .form {
    width: 80%;
  }
  .formWrapper {
    margin: 3.2rem;
  }
}
