/* * Main Container */
.mainTabPanelWrapper {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panelHeader {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* padding: 2rem; */
  padding: 2rem 2rem 1rem 2rem;
  flex: 1;
}
.panelHeader > .dropDownWrapper {
  display: flex;
  align-items: center;
}
.tabPanel {
  flex: 1;
  padding: 0 !important;
  padding-top: 2rem !important;
}
.panelHeader > .dropDownWrapper > div {
  margin-left: 1rem;
}
