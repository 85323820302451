.modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);
    height: min(600px , 90%);
}
/* * header */

.modalWrapper > .header {
    display: flex;
    /* justify-content: space-between; */
    padding: 2rem;
    border-bottom: 1px solid #eaecf0;
}
.modalWrapper > .header h1 {
    margin: 0 1rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #344054;
}
.modalWrapper > .header img:first-child {
    width: 1.3rem;
}
.modalWrapper > .header img:last-child {
    position: absolute;
    right: 1.3rem;
    top: 1.3rem;
    width: 1.2rem;
}

.mainContainer {
    background: #f9fafb;
    padding: 2rem;
    border-bottom: 1px solid #eaecf0;
    flex: 1;
    max-height: 80%;
    overflow-y: auto;
    height: calc(100% - 14.8rem);
}

/* Footer */

.footer {
    padding: 2rem;
    display: flex;
    justify-content: flex-end;
}
.footer > div {
    margin: 0 0.5rem;
}

.inputDiv {
    margin-bottom: 2.6rem;
}

.imageUploadWrapper {
    background: #ffffff;
    border: 1px solid #e4e7ec;
    box-sizing: border-box;
    border-radius: 0.8rem;
    padding: 1rem 1.4rem;
    text-align: center;
    cursor: pointer;
    margin-top: 0.6rem;
}
.uploadImageText {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #667085;
}
.uploadImageSpan {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #6941c6;
}

@media screen and (max-width: 450px) {
    .modalWrapper {
        width: 100%;
        height: 100%;
    }
}
