.searchAndFilterWrapper {
  border: solid 1px #eaecf0;
  padding: 1.2rem 1.6rem 0.5rem 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.searchAndFilterWrapper > div:first-child {
  /* flex-grow: 5; */
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  /* flex: 1; */
}
.searchAndFilterWrapper > div:last-child {
  display: flex;
  margin-bottom: 0.5rem;
  /* flex-grow: 5; */
  align-items: center;
  /* flex: 1; */
  /* column-gap: 12px; */
}
.searchAndFilterWrapper > div:last-child > div:first-child {
  /*? for search */
  /* min-width: calc(100% - 10rem); */
  /* width: calc(100% - 11rem); */
  margin-right: 1rem;
}
/* ? For Date from to container */
.filterDateWrapperWrapper {
  display: flex;
  align-items: center;
}
.filterDateWrapperWrapper > p {
  font-size: 1.5rem;
  margin: 0 1rem;
}

/* ? table */
.examCenterWrapper {
  /* height: calc(100vh - 44rem); */
  flex: 1;
  width: 100%;
}

/* ? wrapper for multi exam */
.mainTabPanelWrapperForMultiExamMeritList {
  /* padding: 1.4rem 2.4rem; */
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mainTabPanelWrapperForMultiExamMeritList > div {
  /*? each list */
  margin-bottom: 1.6rem;
  box-shadow: none;
  /* border-bottom: solid #eaecf0 0.1rem; */
  cursor: default !important;
}
.mainTabPanelWrapperForMultiExamMeritList > div:last-child {
  margin-bottom: 0;
}
.mainTabPanelWrapperForMultiExamMeritList > div:before {
  background-color: #fff;
}
.uploadFileWrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.uploadFileWrapper > p {
  margin: 0;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #344054;
}

/* * Applicants Tab  ~~~~~~~~~~~~~~~~~~~~~~~~~~ 👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓 */

.imageCellWrapper {
  display: flex;
  column-gap: 12px;
}
.imageCellWrapper p {
  margin: 0;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #667085;
}
.imageCellWrapper p:first-child {
  color: #101828;
  font-weight: 500;
}
.doneButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #039855;
  background: #d1fadf;
  border-radius: 1.6rem;
  cursor: pointer;
  text-transform: capitalize;
}
.pendingButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #dc6803;
  background: #fef0c7;
  border-radius: 1.6rem;
  cursor: pointer;
  text-transform: capitalize;
}
.qualifiedButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #039855;
  background: #d1fadf;
  border-radius: 1.6rem;
  cursor: pointer;
  text-transform: capitalize;
}
.downloadButton {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration-line: underline;
  color: #6941c6;
}
