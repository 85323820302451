.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 7px 14px rgba(64, 68, 82, 0.08);
}
/* * header */

.modalWrapper > .header {
  display: flex;
  /* justify-content: space-between; */
  padding: 2rem;
  border-bottom: 1px solid #eaecf0;
}
.modalWrapper > .header h1 {
  margin: 0 1rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #344054;
}
.modalWrapper > .header img:first-child {
  width: 1.3rem;
}
.modalWrapper > .header img:last-child {
  position: absolute;
  right: 1.3rem;
  top: 1.3rem;
  width: 1.2rem;
}

.mainContainer {
  background: #f9fafb;
  padding: 2rem;
  border-bottom: 1px solid #eaecf0;
}

/* own */
.inputContainer{
    display: flex;
    flex-wrap: wrap;
}
.inputWrapper {
  margin: 0 0.5rem 1rem 0.5rem;  
  display: flex;
  flex-direction: column;
  /* flex-grow: 5; */
  /* flex: 33%; */
  flex: 100%;
}
.inputWrapper > label {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #344054;
}



/* Footer */
.footer {
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
}
.footer > div {
  margin: 0 0.5rem;
}

@media screen and (max-width: 450px) {
  .modalWrapper {
    width: 100%;
    height: 100%;
  }
}
