.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 3.2rem 1.6rem;
    font-family: 'Inter-Medium';
}
.app_info {
    display: inline-block;
    width: 100%;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 2.4rem;
    height: 4%;
}
.app_logo {
    display: inline-block;

}
.app_name {
    display: inline-block;

}
.user_profile {
    padding-top: 2.4rem;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.user_info {
    display: inline-block;
    margin-right: 1.2rem;
    vertical-align: middle;
    width: calc(100% - 8.4rem);
    flex: 1;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #667085;
}
.user_name {
    font-family: 'Inter-Medium';
    text-overflow: ellipsis;
    overflow: hidden;
}
.user_email {
    font-family: 'Inter-Regular';
    text-overflow: ellipsis;
    overflow: hidden;
}
.logout {
    /* width: 2rem;
    height: 2rem; */
    padding: 0.6rem;
    cursor: pointer;
}
.logout:focus-visible {
    outline: unset;
    background: rgb(0, 0, 0, 0.12);
}
.menu_array {
    display: inline-block;
    width: 100%;
    flex: 1;
    /* height: 76%; */
    /* height: 86%; */
}