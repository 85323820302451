#demoPdf {
  width: 100%;
}
.examName {
  width: 100%;
  font-size: 20px;
  border-bottom: 2px solid black;
}
h1 {
  margin: 0;
}

.infoTable td {
  font-size: 20px;
}
.infoTable tr > td:first-child {
  padding-right: 20px;
}


.hallTicketDetails {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 1.4rem;
}

.secondTable table {
  width: 100%;
  border-collapse: collapse;
}
.secondTable td {
  font-size: 15px;
  border: 1px solid;
  padding: 5px;
  text-align: center;
}
.secondTable tr > td:first-child {
  padding-right: 20px;
}

.addressWrapper {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addressWrapper td {
  font-size: 15px;
  padding: 5px;
}

.rulesWrapper {
  font-size: 15px;
}
.rulesWrapper {
  font-size: 18px;
  margin-bottom: 10px;
}
.rulesWrapper table td {
  vertical-align: top;
}