.wrapperContent {
  width: calc(100% - 6.4rem);
  height: calc(100% - 6.4rem);
  padding: 3.2rem;
}
.wrapperTitle {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
}
.title {
  font-family: Inter-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 3.8rem;
  /* identical to box height, or 127% */
  /* Gray/900 */
  color: #101828;
  margin-bottom: 0.4rem;
}
.subTitle {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  /* identical to box height, or 150% */

  /* Gray/500 */

  color: #667085;
}
.content {
  display: flex;
  column-gap: 7.6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.wrapperCard {
  width: calc(33% - 4.8rem);
  padding: 2.4rem;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 0.8rem;
  margin-bottom: 2.4rem;
}
.wrapperCardTitle{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2.4rem;
}
.cardTitle {
  font-family: "Inter-Medium";
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #101828;
}
.count{
  width: 80%;
    font-family: 'Inter-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 4.4rem;
    /* identical to box height, or 122% */
    
    letter-spacing: -0.02em;
    
    /* Gray/900 */
    
    color: #101828;
    margin-bottom: 1.6rem;
}
.wrapperRates{
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 0.8rem;
    align-items: center;
}
.wrapperLeftRightCard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}
.TotalRate{
    display: flex;
    flex-direction: column;
}
.wrapperGraph{

}
.percent,.loss{
    font-family: 'Inter-Medium';
font-style: normal;
font-weight: 500;
font-size: 1.4rem;
line-height: 2rem;
/* identical to box height, or 143% */

text-align: center;

/* Success/700 */

color: #027A48;
}
.wrapperProfit{
  display: flex;
  border-radius: 1.6rem;
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  background: #ECFDF3;
  /* identical to box height, or 143% */
  
  text-align: center;
  
  /* Success/700 */
  
  color: #027A48;
  column-gap: 0.7rem;
}
.percentWithBackground,.lossWithBackground{
  border-radius: 1.6rem;
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;

  /* identical to box height, or 143% */
  
  text-align: center;
  
  /* Success/700 */
  

}
.lossWithBackground{

}
.threeDots{
  cursor: pointer;
}
.TotalRateSideBySide{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.loss{
    color: #B42318;
}
.lastMonthText{
    width: 35%;
    font-family: 'Inter-Medium';
font-style: normal;
font-weight: 500;
font-size: 1.4rem;
line-height: 2rem;
/* or 143% */


/* Gray/500 */

color: #667085;
}
@media screen and (min-width:280px) and (max-width:1024px) {
  .content{
    flex-direction: column;
  }
  .wrapperCard{
    width: 100%;
  }
}