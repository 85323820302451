.pageWrapper {
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* * For Header 😍 */
.pageHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 2.7rem 2.7rem 1.4rem 2.7rem;
}
.pageHeader p {
  margin: 0;
}
.pageHeader .logoWrapper {
  display: flex;
  margin: 0.5rem;
}
.pageHeader .logoWrapper img {
  height: 8rem;
  width: 8rem;
  margin-right: 2.4rem;
}
.examHeading {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0em;
  text-align: left;
}
.endExamWrapper > p:first-child {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  color: #667085;
}
.endExamWrapper > p:last-child {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  color: #101828;
}
.numberBoxWrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-evenly;
}
.numberBox {
  padding: 1.5rem;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  margin: 0 1rem;
  /* min-width: 20rem; */
  margin: 0.5rem;
}
.numberBox > p:first-child {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  color: #667085;
}

.numberBox > p:last-child {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3rem;
  color: #101828;
}
.headerButtonWrapper {
  /* display: flex; */
}
.headerButtonWrapper > div {
  margin: 0.5rem;
}
/* * for Tab 😍 */
/** Exam Center ~~~~~~~~~~~~~~~~~~~ 🏠🏠🏠🏠🏠🏠 */
.TabsWrapper {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.tabPanel {
  flex: 1;
  padding: 2.4rem !important;
}
.mainTabPanelWrapper {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  height: 100%;
  padding: 2rem;
}
.mainTabPanelHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 2rem; */
  padding: 2rem 2rem 1rem 2rem;
}

.mainTabPanelHeader > div:first-child {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  color: #667085;
  margin-bottom: 1rem;
}
.mainTabPanelHeader > div:first-child > p {
  margin: 0;
}
.mainTabPanelHeader > div:first-child > p:first-child {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #101828;
}
.mainTabPanelHeader > div:last-child > div {
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  flex: 1;
}
.mainTabPanelHeader > div:last-child {
  display: flex;
  flex-wrap: wrap;
  /* column-gap: 1.5rem; */
}

.searchAndFilterWrapper {
  border: solid 1px #eaecf0;
  padding: 1.2rem 1.6rem 0.5rem 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.searchAndFilterWrapper > div:first-child {
  flex-grow: 5;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.searchAndFilterWrapper > div:last-child {
  display: flex;
  margin-bottom: 0.5rem;
  flex-grow: 5;
  align-items: center;
  /* column-gap: 12px; */
}
.searchAndFilterWrapper > div:last-child > div:first-child {
  /*? for search */
  /* min-width: calc(100% - 10rem); */
  width: calc(100% - 11rem);
  margin-right: 1rem;
}
/* ? table */
.examCenterWrapper {
  /* height: calc(100vh - 44rem); */
  flex: 1;
  width: 100%;
}

/* ? wrapper for multi exam */
.mainTabPanelWrapperForMultiExamMeritList {
  /* padding: 1.4rem 2.4rem; */
}
.mainTabPanelWrapperForMultiExamMeritList > div {
  /*? each list */
  margin-bottom: 1.6rem;
  box-shadow: none;
  border-bottom: solid #eaecf0 0.1rem;
  cursor: default !important;
}
.mainTabPanelWrapperForMultiExamMeritList > div:last-child {
  margin-bottom: 0;
}
.mainTabPanelWrapperForMultiExamMeritList > div:before {
  background-color: #fff;
}

.multiExamMeritListHeader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.multiExamMeritListHeader > P {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: #101828;
  margin: 0;
}
.multiExamMeritListHeader > div:last-child {
  /*upload merit list button*/
  margin-right: 2rem;
}

.multiExamMeritListDetails {
  padding: 0 !important;
}

.uploadFileWrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.uploadFileWrapper > p {
  margin: 0;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #344054;
}

/* * Applicants Tab  ~~~~~~~~~~~~~~~~~~~~~~~~~~ 👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓👨‍🎓 */

.imageCellWrapper {
  display: flex;
  column-gap: 12px;
}
.imageCellWrapper p {
  margin: 0;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #667085;
}
.imageCellWrapper p:first-child {
  color: #101828;
  font-weight: 500;
}
.doneButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #039855;
  background: #d1fadf;
  border-radius: 16px;
  cursor: pointer;
  text-transform: capitalize;
}
.pendingButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #dc6803;
  background: #fef0c7;
  border-radius: 16px;
  cursor: pointer;
  text-transform: capitalize;
}
.qualifiedButton {
  padding: 2px 10px;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #039855;
  background: #d1fadf;
  border-radius: 16px;
  cursor: pointer;
  text-transform: capitalize;
}

/* ! Media Queries */
@media screen and (max-width: 1000px) {
  .pageHeader > div:first-child {
    order: 0;
  }
  .numberBoxWrapper {
    order: 2;
    width: 100%;
    flex: unset;
  }
  .numberBox{
    flex: 1;
  }
  .pageHeader > div:last-child > div{
    margin-bottom: 0.8rem;
  }
  .pageHeader > div:last-child {
    order: 1;
  }
}
