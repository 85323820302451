@font-face {
    font-family: "Inter-Medium";
    src: url("./assets/font/Inter-Medium.ttf");
}
@font-face {
    font-family: "Inter-Regular";
    src: url("./assets/font/Inter-Regular.ttf");
}
@font-face {
    font-family: "Inter-SemiBold";
    src: url("./assets/font/Inter-SemiBold.ttf");
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Inter-Regular";
    color: #000000;
    font-size: 10px;
}
#root {
    width: 100%;
    height: 100%;
}
p {
    margin: 0;
}
/* width */
::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    border: 1px solid #d5d5d5;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px #fff;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

@media screen and (max-width: 1366px) {
    html,
    body {
        font-size: 8px;
    }
}
@media screen and (max-width: 767px) {
    html,
    body {
        font-size: 7px;
    }
}
